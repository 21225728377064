import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1> webFlowga is under construction</h1>
        <p> Please check back later</p>
      </header>
    </div>
  );
}

export default App;
